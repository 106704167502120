//@author: sara
import { CurrencyFormatPipe, DateFormatPipe, DurationPipe, HTMLPipe, SafeLink, SortByPipe } from "./s25.pipes";
import { NgModule } from "@angular/core";

@NgModule({
    declarations: [SafeLink, DurationPipe, SortByPipe, DateFormatPipe, HTMLPipe, CurrencyFormatPipe],
    exports: [SafeLink, DurationPipe, SortByPipe, DateFormatPipe, HTMLPipe, CurrencyFormatPipe],
    providers: [SafeLink, DurationPipe, SortByPipe, DateFormatPipe, HTMLPipe, CurrencyFormatPipe],
})
export class S25PipesModule {}
