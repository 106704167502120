import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { S25SevenPointSolutionsSettingsComponent } from "./s25.seven.point.solutions.settings.component";
import { S25ToggleButtonModule } from "../../s25-toggle-button/s25.toggle.button.module";
import { S25IconModule } from "../../s25-icon/s25.icon.module";

@NgModule({
    imports: [CommonModule, FormsModule, S25ToggleButtonModule, S25IconModule],
    exports: [S25SevenPointSolutionsSettingsComponent],
    declarations: [S25SevenPointSolutionsSettingsComponent],
    providers: [S25SevenPointSolutionsSettingsComponent],
})
export class S25SevenPointSolutionsSettingsModule {}
