//@author: Mandy
import { jSith } from "../../util/jquery-replacement";

declare global {
    interface Window {
        angBridge: any;
    }
}
export class SystemSettingsUtil {
    /*
    public static decorateOptions(getOptions: any, displayOptions : any){        
        let ret : any = [];
        getOptions && jSith.forEach(getOptions, (key : any, g : any) => {
            let show = displayOptions.filter((item : any) => item.section_id === g.section_id);
            show.length > 0 ? ret.push({...g, display_name: show[0].name, show: true }) : ret.push({...g, display_name: '', show: false });              
        });
        return ret; 
     }
     */

    //ANG-3488
    public static decorateOptions(getOptions: any, displayOptions: any) {
        let ret: any = [];
        jSith.forEach(displayOptions, (key: any, g: any) => {
            let show = getOptions.filter((item: any) => item.section_id === g.section_id);
            show.length > 0
                ? ret.push({ ...show[0], display_name: g.name, show: true })
                : ret.push({ ...g, visible: false, display_name: g.name, show: true });
        });
        return ret;
    }

    public static setOptions(object: any, options: any) {
        let newValue: any = "";
        options &&
            jSith.forEach(options, (key: any, g: any) => {
                newValue +=
                    '<index name="' +
                    g.name +
                    '"' +
                    ' section_id="' +
                    g.section_id +
                    '"' +
                    ' visible="' +
                    g.visible +
                    '"></index>';
            });

        if (newValue !== "") {
            let optName = SystemSettingsUtil.getOptionName(object);
            newValue = "<" + optName + ">" + newValue + "</" + optName + ">";
        }
        return newValue;
    }

    public static getOptionName(object: any) {
        let setValue: string = "";
        switch (object) {
            case "event":
                setValue = "event_options";
                break;
            case "location":
                setValue = "location_options";
                break;
            case "resource":
                setValue = "resource_options";
                break;
            case "organization":
                setValue = "organization_options";
                break;
        }
        return setValue;
    }

    public static displayOptions(object: any) {
        let displayOptions: any = [];
        switch (object) {
            case "event":
                displayOptions = [
                    { section_id: "cabinet", name: "Cabinets" },
                    { section_id: "organization", name: "Organizations" },
                    { section_id: "type", name: "Types" },
                    { section_id: "category", name: "Categories" },
                    { section_id: "role", name: "Roles" },
                    { section_id: "state", name: "States" },
                    { section_id: "event_collection_default", name: "Public Event Searches" },
                    // { section_id: "space_collection_default", name: "Public Location Searches" }, // ANG-4630 Hiding this setting as it is not tied to anything
                ];
                break;
            case "location":
                displayOptions = [
                    { section_id: "category", name: "Categories" },
                    { section_id: "feature", name: "Features" },
                    { section_id: "layout", name: "Layouts" },
                    { section_id: "capacity", name: "Capacities" },
                    { section_id: "building", name: "Buildings" },
                    { section_id: "default_collection", name: "Public Searches" },
                ];
                break;
            case "resource":
                displayOptions = [
                    { section_id: "category", name: "Categories" },
                    { section_id: "default_collection", name: "Public Searches" },
                ];
                break;
            case "organization":
                displayOptions = [
                    { section_id: "category", name: "Categories" },
                    { section_id: "type", name: "Types" },
                    { section_id: "default_collection", name: "Public Searches" },
                ];
                break;
        }
        return displayOptions;
    }
}
