import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { S25Util } from "../../../util/s25-util";
import { Integration, IntegrationService, IntegrationTypes } from "../../integrations/integration.service";
import { AlertService } from "../../../services/alert.service";

@TypeManagerDecorator("s25-ng-seven-point-solutions-settings")
@Component({
    selector: "s25-ng-seven-point-solutions-settings",
    template: `@if (init) {
        <div>
            <h2>7 Point Solutions Settings</h2>
            <s25-toggle-button
                class="ngBlock"
                [(modelValue)]="sevenPointSolutions.enabled"
                (modelValueChange)="onEnableToggle()"
                [toggleLabel]="'Enable 7 Point Solutions Payments'"
                [falseLabel]="'Disabled'"
                [trueLabel]="'Enabled'"
            ></s25-toggle-button>
            <label class="c-margin-top--single ngBlock">
                7 Point Static Payment Url
                <input
                    [disabled]="!sevenPointSolutions.enabled"
                    type="text"
                    class="c-input c-margin-right--half c-margin-left--half"
                    [(ngModel)]="sevenPointSolutions.url"
                />
            </label>
            <button class="aw-button aw-button--primary ngBlock c-margin-top--single" (click)="save()">Save</button>
            <div class="save-msg c-margin-top--single" [class.saved]="showSaveMsg">
                <s25-ng-icon [type]="'check'" />
                Settings saved successfully
            </div>
        </div>
    }`,
    styles: `
        .save-msg {
            opacity: 0;
            transition: opacity 0.5s ease-out;
            color: green;
        }

        .save-msg.saved {
            opacity: 1;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25SevenPointSolutionsSettingsComponent implements OnInit {
    init: boolean;
    sevenPointSolutions: Integration;
    showSaveMsg: boolean;

    constructor(private cd: ChangeDetectorRef) {}

    async ngOnInit() {
        this.sevenPointSolutions = await IntegrationService.getIntegration(IntegrationTypes.sevenPoint);
        if (!this.sevenPointSolutions) {
            this.sevenPointSolutions = await IntegrationService.createIntegration({
                apiKey: "",
                url: "",
                enabled: false,
                integrationType: IntegrationTypes.sevenPoint,
            });
        }
        this.init = true;
        this.cd.detectChanges();
    }

    onEnableToggle = () => {
        this.cd.detectChanges();
    };

    save = S25Util.debounceTs(async () => {
        if (this.sevenPointSolutions.enabled && !this.sevenPointSolutions.url) {
            return AlertService.alert("A static payment URL provided by 7 Point Solutions is required.");
        }

        let promises: Promise<any>[] = [];
        promises.push(
            IntegrationService.setEnabled(
                this.sevenPointSolutions.integrationId,
                this.sevenPointSolutions.integrationType,
                this.sevenPointSolutions.enabled,
            ),
        );
        promises.push(IntegrationService.setUrl(this.sevenPointSolutions.integrationId, this.sevenPointSolutions.url));
        try {
            await S25Util.all(promises);
            this.showSaveMsg = true;
            this.cd.detectChanges();
            setTimeout(() => {
                this.showSaveMsg = false;
                this.cd.detectChanges();
            }, 5000);
        } catch (e) {
            S25Util.showError(e);
        }
    });
}
