import { S25Const } from "../../util/s25-const";
import { S25Util } from "../../util/s25-util";

export class TinymceLoader {
    private static ScriptId = "tinymce-script";
    private static IsLoaded = false;

    public static isLoaded() {
        return TinymceLoader.IsLoaded;
    }

    public static load(): Promise<boolean> {
        let path: string = S25Const.isDevMode
            ? "/25power/resources/typescript/assets/tinymce/tinymce.min.js"
            : "resources/typescript/assets/tinymce/tinymce.min.js";
        return S25Util.loadScript(TinymceLoader.ScriptId, path).then(() => {
            TinymceLoader.IsLoaded = true;
            return true;
        });
    }
}
