//@author: mandy

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { SystemDefaultsComponent } from "./system.defaults.component";
import { S25ToggleButtonModule } from "../../s25-toggle-button/s25.toggle.button.module";
import { S25RichTextEditorModule } from "../../s25-rich-text-editor/s25.rich.text.editor.module";

@NgModule({
    declarations: [SystemDefaultsComponent],
    imports: [CommonModule, FormsModule, S25ToggleButtonModule, S25RichTextEditorModule],
    providers: [SystemDefaultsComponent],
})
export class SystemDefaultsModule {
    constructor() {}
}
