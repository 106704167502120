import {
    ChangeDetectionStrategy,
    Component,
    computed,
    ElementRef,
    NgZone,
    OnInit,
    signal,
    ViewChild,
} from "@angular/core";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { Table } from "../../../s25-table/Table";
import { Bind } from "../../../../decorators/bind.decorator";
import { Lock, LockService } from "../../../../services/lock.service";
import { FlsService } from "../../../../services/fls.service";
import { ContactService } from "../../../../services/contact.service";
import { UserprefService } from "../../../../services/userpref.service";
import { S25Datefilter } from "../../../s25-dateformat/s25.datefilter.service";
import { S25TableComponent } from "../../../s25-table/s25.table.component";
import { S25Util } from "../../../../util/s25-util";
import { S25LoadingApi } from "../../../s25-loading/loading.api";
import { GenericTableButtonComponent } from "../../../s25-table/generics/generic.table.button.component";

@TypeManagerDecorator("s25-ng-lock-table")
@Component({
    selector: "s25-ng-lock-table",
    template: `
        @if (init) {
            <div class="lock-table-wrapper">
                <h2 class="c-margin-bottom--single">Locked Items</h2>
                <span class="btn btn-flat" (click)="refresh()">
                    <s25-ng-icon [type]="'refresh'"></s25-ng-icon>
                </span>
                @if (removableLocks().length) {
                    <button
                        #removeAllButton
                        (click)="deleteLocks()"
                        class="aw-button aw-button--primary c-margin-bottom--single c-margin-right--single remove-all-button"
                        [disabled]="deleteAllProcessing()"
                    >
                        <s25-loading-inline [model]="{}"></s25-loading-inline>
                        {{ deleteAllProcessing() ? "" : "Remove All" }}
                    </button>
                }
                @if (locksToDelete().length) {
                    <button
                        #removeSelectedButton
                        class="aw-button aw-button--outline c-margin-bottom--single remove-selected-button"
                        (click)="deleteLocks(locksToDelete(), false)"
                    >
                        <s25-loading-inline [model]="{}"></s25-loading-inline>
                        {{ deleteSelectedProcessing() ? "" : "Remove Selected" }}
                    </button>
                }
                @if (showRemoveAllWarning()) {
                    <div class="lock-perms-warning cn-alert__icon cn-icon cn-alert--warning">
                        <s25-ng-icon [type]="'warningYield'"></s25-ng-icon>
                        <div>Insufficient permissions to remove all locks</div>
                    </div>
                }
                <s25-ng-table
                    [dataSource]="tableConfig"
                    [hasFilter]="true"
                    [hasSelect]="true"
                    [hasSelectAll]="true"
                    [align]="'center'"
                    [pivotThreshold]="900"
                    (selectedChange)="updateLocksToDelete($event)"
                ></s25-ng-table>
            </div>
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25LockTableComponent implements OnInit {
    init: boolean;
    tableConfig: Table.DataSource;
    locks = signal<Lock[]>([]);
    removableLocks = computed<Lock[]>(() => this.locks().filter((lock) => !lock.disabled));
    locksToDelete = signal<Lock[]>([]);
    showRemoveAllWarning = signal(false);
    deleteAllProcessing = signal(false);
    deleteSelectedProcessing = signal(false);

    columns: Table.Column[] = [
        { id: "item", header: "Item" },
        { id: "type", header: "Type" },
        { id: "owner", header: "Owner" },
        { id: "email", header: "Email" },
        { id: "phone", header: "Phone" },
        { id: "date", header: "Date" },
        GenericTableButtonComponent.Column("Delete", this.onDelete),
    ];

    @ViewChild(S25TableComponent) tableComp: S25TableComponent;
    @ViewChild("removeAllButton") removeAllButton: ElementRef<HTMLButtonElement>;
    @ViewChild("removeSelectedButton") removeSelectedButton: ElementRef<HTMLButtonElement>;

    constructor(private zone: NgZone) {}

    ngOnInit() {
        this.tableConfig = {
            type: "unpaginated",
            dataSource: this.getRows,
            columns: this.columns,
        };
        this.init = true;
    }

    @Bind
    async getRows(): Promise<Table.DataSourceResponse> {
        const [allLocks, fls, currentUsername, dateTimePref] = await Promise.all([
            LockService.getLocks(),
            FlsService.getFls(),
            ContactService.getCurrentUsername(),
            UserprefService.getS25DateTimeformat(),
        ]);

        this.locks.set(
            allLocks?.map((lock) => {
                lock.disabled =
                    ["F", "C"].indexOf(fls.LOCK) === -1 || (fls.LOCK === "C" && lock.last_mod_user !== currentUsername);
                return lock;
            }),
        );

        return {
            rows: this.locks().map((lock) => {
                return {
                    id: lock.lock_item_id,
                    name: lock.lock_item_name,
                    data: { lock },
                    cells: {
                        item: { text: lock.lock_item_name },
                        type: { text: lock.lock_item_type_name },
                        owner: { text: lock.last_mod_user },
                        email: { text: lock.email_address },
                        phone: { text: lock.contact_phone },
                        date: { text: S25Datefilter.transform(lock.last_mod_dt, dateTimePref) },
                        delete: {
                            inputs: {
                                label: "Delete",
                                type: "danger--outline",
                                disabled: lock.disabled,
                            },
                        },
                    },
                };
            }),
        };
    }

    @Bind
    async onDelete(row: Table.Row, button: GenericTableButtonComponent) {
        const lockId = row.data.lock.lock_item_id;
        const lockType = row.data.lock.lock_item_type;
        button.isLoading = true;
        await LockService.delete(lockId, lockType);
        this.zone.run(() =>
            this.locks.update((locks: Lock[]) => locks.filter((lock) => lock.lock_item_id !== lockId.toString())),
        );
        await this.tableComp.refresh();
    }

    async deleteLocks(locks?: Lock[], removeAll: boolean = true) {
        locks ??= this.removableLocks();
        const btnEl = removeAll ? this.removeAllButton.nativeElement : this.removeSelectedButton.nativeElement;
        const processing = removeAll ? this.deleteAllProcessing : this.deleteSelectedProcessing;

        S25LoadingApi.init(btnEl);
        processing.set(true);

        const typeMap = locks?.reduce((map: { [key: string]: Lock[] }, lock) => {
            if (!map[lock.lock_item_type]) {
                map[lock.lock_item_type] = [lock];
            } else {
                map[lock.lock_item_type].push(lock);
            }
            return map;
        }, {});

        try {
            await Promise.all(Object.values(typeMap).map((locks: Lock[]) => LockService.deleteLocks(locks)));
            await this.tableComp.refresh();
            this.showRemoveAllWarning.set(this.locks().length !== this.removableLocks().length);
        } catch (e) {
            S25Util.showError(e);
        }

        S25LoadingApi.destroy(btnEl);
        processing.set(false);
        this.zone.run(() => this.locksToDelete.set([]));
    }

    refresh() {
        return this.tableComp.refresh();
    }

    updateLocksToDelete(ids: Set<Table.Row["id"]>) {
        this.zone.run(() => {
            this.locksToDelete.set(this.removableLocks().filter((lock) => ids.has(lock.lock_item_id)));
        });
    }
}
