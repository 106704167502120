import { TypeManagerDecorator } from "../../../main/type.map.service";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, Input, ViewEncapsulation } from "@angular/core";
import { PreferenceService } from "../../../services/preference.service";
import { Preference } from "../../../pojo/PeferenceI";
import { jSith } from "../../../util/jquery-replacement";
import { S25ButtonComponent } from "../../../standalone/s25.button.component";
import { FormsModule } from "@angular/forms"; // Import FormsModule

@TypeManagerDecorator("s25-ng-sis-import-export-settings")
@Component({
    selector: "s25-ng-sis-import-export-settings",
    template: `@if (init) {
        <form>
            <div class="sis-import-export-wrapper">
                <h2>Import/Export</h2>
                <p class="ngFinePrint">
                    Note:These settings do not affect any version of the LYNX interface. LYNX contains its own settings
                    that are pertinent to its internal processes. These settings only affect vCal files imported or
                    exported via legacy Series25-SIS Interface products or stand-alone web services.
                </p>

                <s25-ng-button
                    class="c-margin-top--single c-margin-right--half"
                    (click)="save()"
                    [type]="'primary'"
                    [buttonText]="saveButton"
                    [isLoading]="loading"
                ></s25-ng-button>
                <s25-ng-button (click)="getData()" [type]="'outline'">Cancel</s25-ng-button>
                <div class="sis-import-export">
                    @for (setting of settings; track setting.prefName) {
                        <h3 class="ngBold c-margin-top--single">{{ setting.title }}</h3>
                        @for (option of setting.options; track option.val) {
                            <div class="radio-option">
                                <input
                                    [id]="setting.prefName + '_' + option.val"
                                    type="radio"
                                    name="{{ setting.prefName }}"
                                    value="{{ option.val }}"
                                    [(ngModel)]="setting.val"
                                    (ngModelChange)="setting.changed = true"
                                />
                                <label for="{{ setting.prefName + '_' + option.val }}"> {{ option.label }} </label>
                            </div>
                        }
                        <p class="ngFinePrint ">{{ setting.description }}</p>
                    }
                </div>

                <s25-ng-button
                    class="c-margin-top--single c-margin-right--half"
                    (click)="save()"
                    [type]="'primary'"
                    [buttonText]="saveButton"
                    [isLoading]="loading"
                ></s25-ng-button>
                <s25-ng-button (click)="getData()" [type]="'outline'">Cancel</s25-ng-button>
            </div>
        </form>
    }`,
    //add styles, each input element should be on its own line. Setting.label should be bold
    styles: `
        .sis-import-export-wrapper {
            padding: 20px;
            .sis-import-export {
            }
        }
    `,
    standalone: true,
    imports: [FormsModule, S25ButtonComponent],
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * SIS Import/Export settings migrated directly from backbase admin tool. Lynx schools should be configuring this via the Lynx interface.
 */
export class SisImportExportSettingsComponent implements OnInit {
    init: boolean;
    preferences: Preference.prefData<string, "S", any>;
    saveButton = "Save";
    loading = false;

    constructor(private cd: ChangeDetectorRef) {}

    settings = [
        {
            title: "Automatically generate a REPLY:",
            prefName: "vCalForceReply",
            description:
                'Select "For Tentative and Confirmed event states" to automatically generate REPLY files when sections successfully import with an appropriate location assignment. Institutions importing section data in unlocked transactional processing should also choose this option.',
            options: [
                { val: "1\\;2", label: "For Tentative and Confirmed event states" },
                { val: "", label: "For no event states" },
            ],
        },
        {
            title: "Use LOCATION settings for this Student Information System",
            prefName: "vCalLocation",
            description:
                "Choose the Student Information System that your site uses to set the LOCATION property style to Occurrence (for Oracle's PeopleSoft Campus Solutions) or Optimum (for Banner, Colleague and all other systems).",
            options: [
                { val: "OCCURRENCE", label: "Oracle's PeopleSoft Campus Solutions" },
                { val: "OPTIMUM", label: "Ellucian Banner, Ellucian Colleague or any others" },
            ],
        },
        {
            title: "Academic Priority Location Assignments",
            prefName: "vCalAcademicPriority",
            description:
                "This setting lets you determine whether or not imported sections should take precedence over non-academic events in location assignment. If set to “On,” an imported section with a pre-assigned location will be assigned that location, even if there is a non-academic event that already has the same location assigned for some or all of the same dates/times. The location assignment of the non-academic event will be dropped, and the event's scheduler will be notified via email.",
            options: [
                { val: 1, label: "On" },
                { val: 0, label: "Off" },
            ],
        },
        {
            title: "When not all dates are available for a location:",
            prefName: "vCalAssignAllDates",
            description:
                "This setting applies when an imported section with a pre-assigned location encounters a previously imported section that has been assigned the same location at the same dates/times for some or all of its occurrences. If you've chosen \"assign the location anyway,\" the section with the pre-assignment will have its pre-assigned location assigned to just the available occurrences. As a result, some of the section's occurrences may not have a location assignment. This setting is also dependent on the Academic Priority setting.",
            options: [
                { val: "F", label: "Assign the location anyway" },
                { val: "T", label: "Do not assign the location" },
            ],
        },
        {
            title: "Manage imported cancelled sections by",
            prefName: "vCalCancel",
            description:
                "Choose whether to change a section's state to cancelled or delete the section when the section has been cancelled in your SIS.",
            options: [
                { val: 1, label: "Changing the event state to Cancelled" },
                { val: 2, label: "Deleting the event" },
            ],
        },
        {
            title: "Type of interface used",
            prefName: "vCalClearDefinitions",
            description:
                "Choose whether to clear all reservations on a TRANSP:1 import. Checking the first option indicates that DTSTART and DTEND values should not be considered class reservations. Choose the second option only if you are using a datain.dat interface.",
            options: [
                { val: "T", label: "Typical vCalendar interface, clear all reservations on TRANSP:1 import" },
                { val: "F", label: "Datain.dat interface, do not clear reservations on TRANSP:1 import" },
            ],
        },
        {
            title: "Asynchronous vCalendar email notifications",
            prefName: "vCalEmailNotify",
            description:
                "Choose an option to control how often the Asynchronous vCalendar process notifies you by email during processing. A group of sections is usually around 500 sections, depending on how much data is being sent.",
            options: [
                { val: 0, label: "No email sent" },
                { val: 3, label: "One email sent per group of sections" },
                { val: 1, label: "One email sent per group of sections with errors" },
                { val: 2, label: "One email sent per group of sections with errors and warnings" },
            ],
        },
        {
            title: "Asynchronous vCalendar Academic Priority email recipients:",
            prefName: "vCalAcademicPriorityEmail",
            description:
                "Choose an option to indicate your preference for email notification when the Asynchronous vCalendar Academic Priority option trumps existing location assignments.",
            options: [
                { val: 0, label: "No email sent" },
                { val: 1, label: "Email sent to interface user only" },
                { val: 2, label: "Email sent to trumped event schedulers only (Default)" },
                { val: 3, label: "Email sent to interface user AND trumped event schedulers" },
            ],
        },
    ];

    async ngOnInit() {
        await this.getData();
        this.init = true;
        this.cd.detectChanges();
    }

    async getData() {
        const prefs = [
            "vCalForceReply",
            "vCalLocation",
            "vCalAssignAllDates",
            "vCalCancel",
            "vCalClearDefinitions",
            "vCalEmailNotify",
            "vCalAcademicPriority",
            "vCalAcademicPriorityEmail",
        ];
        this.preferences = await PreferenceService.getPreferences(prefs, "S");
        jSith.forEach(this.settings, (key, setting) => {
            setting.val = this.preferences[setting.prefName].value;
        });
        this.cd.detectChanges();
    }

    async save() {
        this.loading = true;
        let promises: Promise<any>[] = [];
        jSith.forEach(this.settings, (key, setting) => {
            if (setting.changed) {
                promises.push(PreferenceService.setPreference(setting.prefName, setting.val, "S"));
            }
        });
        await Promise.all(promises);
        await this.getData();
        this.loading = false;
        this.cd.detectChanges();
    }
}
