import {
    ChangeDetectionStrategy,
    Component,
    computed,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    signal,
    ViewChild,
} from "@angular/core";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { Table } from "../../../s25-table/Table";
import { Bind } from "../../../../decorators/bind.decorator";
import { S25TableComponent } from "../../../s25-table/s25.table.component";
import { S25Util } from "../../../../util/s25-util";
import { PendingObjectService } from "../../../../services/pending.object.service";
import { S25Datefilter } from "../../../s25-dateformat/s25.datefilter.service";
import { ContactService } from "../../../../services/contact.service";
import { FlsService } from "../../../../services/fls.service";
import { UserprefService } from "../../../../services/userpref.service";
import { EventResourceReservation, EventSpaceReservation } from "../../../../services/reservation.service";
import { GenericTableButtonComponent } from "../../../s25-table/generics/generic.table.button.component";
import { S25LoadingApi } from "../../../s25-loading/loading.api";

@TypeManagerDecorator("s25-ng-pending-object-reservations")
@Component({
    selector: "s25-ng-pending-object-reservations",
    template: `
        @if (init) {
            <div class="pending-rsrv-table">
                <h2 class="c-margin-bottom--single">
                    Pending {{ itemTypeId === 4 ? "Location" : "Resource" }} Reservations
                </h2>
                <span class="btn btn-flat" (click)="refresh()">
                    <s25-ng-icon [type]="'refresh'"></s25-ng-icon>
                </span>
                @if (removableRsrvs().length) {
                    <button
                        #removeAllButton
                        class="aw-button aw-button--primary c-margin-bottom--single c-margin-right--single remove-all-button"
                        (click)="deleteAllRsrvs()"
                    >
                        <s25-loading-inline [model]="{}"></s25-loading-inline>
                        {{ deleteAllProcessing() ? "" : "Remove All" }}
                    </button>
                }
                @if (rsrvsToDelete().length) {
                    <button
                        #removeSelectedButton
                        class="aw-button aw-button--outline c-margin-bottom--single remove-selected-button"
                        (click)="deleteRsrvs()"
                    >
                        <s25-loading-inline [model]="{}"></s25-loading-inline>
                        {{ deleteSelectedProcessing() ? "" : "Remove Selected" }}
                    </button>
                }
                @if (showRemoveAllWarning()) {
                    <div class="lock-perms-warning cn-alert__icon cn-icon cn-alert--warning">
                        <s25-ng-icon [type]="'warningYield'"></s25-ng-icon>
                        <div>Insufficient permissions to remove all pending reservations</div>
                    </div>
                }
                <s25-ng-table
                    [dataSource]="tableConfig"
                    [hasFilter]="true"
                    [hasSelect]="true"
                    [hasSelectAll]="true"
                    [align]="'center'"
                    [pivotThreshold]="900"
                    (selectedChange)="updateRsrvsToDelete($event)"
                ></s25-ng-table>
            </div>
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25PendingObjectReservationsComponent implements OnInit {
    @Input() itemTypeId: 4 | 6;

    init: boolean;
    tableConfig: Table.DataSource;

    allRsrvs = signal([]);
    removableRsrvs = computed(() => this.allRsrvs().filter((res) => !res.disabled));
    rsrvsToDelete = signal([]);
    deleteAllProcessing = signal(false);
    deleteSelectedProcessing = signal(false);
    showRemoveAllWarning = signal(false);

    columns: Table.Column[] = [
        { id: "item", header: "Item" },
        { id: "eventName", header: "Event Name" },
        { id: "start", header: "Start" },
        { id: "end", header: "End" },
        { id: "reserveBy", header: "Reserved By" },
        { id: "modified", header: "Last Modified" },
        GenericTableButtonComponent.Column("Delete", this.onDelete),
    ];

    @ViewChild(S25TableComponent) tableComp: S25TableComponent;
    @ViewChild("removeAllButton") removeAllButton: ElementRef<HTMLButtonElement>;
    @ViewChild("removeSelectedButton") removeSelectedButton: ElementRef<HTMLButtonElement>;

    constructor(private zone: NgZone) {}

    ngOnInit() {
        this.tableConfig = {
            type: "unpaginated",
            dataSource: this.getRows,
            columns: this.columns,
        };
        this.init = true;
    }

    @Bind
    async getRows(): Promise<Table.DataSourceResponse> {
        const start = S25Datefilter.transform(S25Util.date.addYears(S25Util.date.currentDate(), -3), "yyyyMMdd");
        const end = S25Datefilter.transform(S25Util.date.addYears(S25Util.date.currentDate(), 3), "yyyyMMdd");

        const [pendRsrvs, fls, currentUsername, dateTimeFormat] = await Promise.all([
            PendingObjectService.get(this.itemTypeId, start, end),
            FlsService.getFls(),
            ContactService.getCurrentUsername(),
            UserprefService.getS25DateTimeformat(),
        ]);

        this.allRsrvs.set(pendRsrvs);

        return {
            rows: pendRsrvs.map((res) => {
                const { id, resName } = this.getIdAndName(res);
                res.disabled =
                    ["F", "C"].indexOf(fls.LOCK) === -1 || (fls.LOCK === "C" && res.last_mod_user !== currentUsername);

                return {
                    id: id,
                    name: resName,
                    data: { eventId: res.event_id, id },
                    cells: {
                        item: { text: resName },
                        eventName: { text: res.event_name },
                        start: { text: S25Datefilter.transform(res.rsrv_start_dt, dateTimeFormat) },
                        end: { text: S25Datefilter.transform(res.rsrv_end_dt, dateTimeFormat) },
                        reserveBy: { text: res.last_mod_user },
                        modified: { text: S25Datefilter.transform(res.last_mod_dt, dateTimeFormat) },
                        delete: {
                            inputs: {
                                label: "Delete",
                                type: "danger--outline",
                                disabled: res.disabled,
                            },
                        },
                    },
                };
            }),
        };
    }

    @Bind
    getIdAndName(res: EventResourceReservation | EventSpaceReservation) {
        let id, resName;
        if ("space_id" in res) {
            id = res.space_id;
            resName = res.space_name;
        } else if ("resource_id" in res) {
            id = res.resource_id;
            resName = res.resource_name;
        }

        return { id, resName };
    }

    refresh() {
        return this.tableComp.refresh();
    }

    @Bind
    async onDelete(row: Table.Row, button: GenericTableButtonComponent) {
        button.isLoading = true;
        await PendingObjectService.delete(row.data.eventId, row.data.id, this.itemTypeId);
        await this.refresh();
    }

    updateRsrvsToDelete(ids: Set<Table.Row["id"]>) {
        this.zone.run(() =>
            this.rsrvsToDelete.set(this.removableRsrvs().filter((res) => ids.has(this.getIdAndName(res)?.id))),
        );
    }

    async deleteRsrvs() {
        S25LoadingApi.init(this.removeSelectedButton.nativeElement);
        this.deleteSelectedProcessing.set(true);

        try {
            await Promise.all(
                this.rsrvsToDelete().map((res) =>
                    PendingObjectService.delete(res.event_id, this.getIdAndName(res)?.id as number, this.itemTypeId),
                ),
            );
            this.zone.run(() => this.rsrvsToDelete.set([]));
            await this.refresh();
            this.setPermsWarning();
        } catch (e) {
            S25Util.showError(e);
        }
    }

    async deleteAllRsrvs() {
        S25LoadingApi.init(this.removeAllButton.nativeElement);
        this.deleteAllProcessing.set(true);

        const eventIds = this.removableRsrvs().map((res) => res.event_id);

        try {
            await Promise.all(eventIds.map((eventId) => PendingObjectService.deleteAll(eventId, this.itemTypeId)));
            await this.refresh();
            this.setPermsWarning();
        } catch (e) {
            S25Util.showError(e);
        }
    }

    setPermsWarning() {
        this.showRemoveAllWarning.set(this.removableRsrvs().length !== this.allRsrvs().length);
    }
}
