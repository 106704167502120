import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { S25LockTableComponent } from "./s25-locks/s25.lock.table.component";
import { S25TableModule } from "../../s25-table/s25.table.module";
import { S25LoadingInlineModule } from "../../s25-loading/s25.loading.inline.module";
import { S25PendingObjectReservationsComponent } from "./s25-pending-object-reservations/s25.pending.object.reservations.component";
import { S25SignedInUsersComponent } from "./s25-signed-in-users/s25.signed.in.users.component";
import { S25OptModule } from "../../s25-opt/s25.opt.module";
import { S25IconModule } from "../../s25-icon/s25.icon.module";
import { S25ItemModule } from "../../s25-item/s25.item.module";

@NgModule({
    declarations: [S25LockTableComponent, S25PendingObjectReservationsComponent, S25SignedInUsersComponent],
    imports: [
        CommonModule,
        FormsModule,
        S25TableModule,
        S25LoadingInlineModule,
        S25OptModule,
        S25IconModule,
        S25ItemModule,
    ],
    providers: [S25LockTableComponent, S25PendingObjectReservationsComponent, S25SignedInUsersComponent],
    exports: [S25LockTableComponent, S25PendingObjectReservationsComponent, S25SignedInUsersComponent],
})
export class UserActivityModule {
    constructor() {}
}
